import React from 'react'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'styles/all.scss'

const Layout = ({ children }) => (
  <>
    <Header />
    <>{children}</>
    <Footer />
  </>
)

export default Layout
